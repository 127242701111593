import { PageProps } from "gatsby"
import React, { FC, useContext, useEffect, useState } from "react"
import { InventoryClient } from "../clients/InventoryClient"
import { Vehicle } from "../global"
import { Layout } from "../components/atoms/Layout"
import tw from "twin.macro"
import { LocalStorageClient } from "../clients/LocalStorageClient"
import { LocalStorageValue } from "../clients/LocalStorageClient"
import Highlights from "../components/organisms/InventoryDetail/Highlights"
import Features from "../components/organisms/InventoryDetail/Features"
import Specs from "../components/organisms/InventoryDetail/Specs"
import VehicleInfo from "../components/organisms/InventoryDetail/VehicleInfo"
import VehicleGallery from "../components/organisms/InventoryDetail/VehicleGallery"
import VehicleDetailOtherOffers from "../components/organisms/InventoryDetail/OtherOffers"
import SimilarVehicles from "../components/organisms/InventoryDetail/SimilarVehicles"
import Lottie from "react-lottie-player"
import lottieJson from "../animations/14717-sedan-car-animation.json"
import { LanguageContext } from "../contexts/Language"
import { Pill } from "../components/atoms/Pill"
import useTealiumView from "../hooks/Tealium/useTealiumView"
import {
  firstLettersCapital,
  generateInventoryLink,
  remove4by,
  sanitizeSeries,
  toUsd,
  vehicleCategory,
} from "../helpers"
import ReactPannellum from "react-pannellum"
import useCustomCarouselEvent from "../hooks/Tealium/useCustomCarouselEvent"
import { DisclaimersContext } from "../contexts/Disclaimers"
import _ from "lodash"
import { setDisclaimers } from "../contexts/Disclaimers/actions"
import { useTealiumContext } from "../contexts/Tealium"
import { Back } from "../components/atoms/Back"
import useTealiumEvent from "../hooks/Tealium/useTealiumEvent"
import { LocationContext } from "../contexts/Location"
import { SEO } from "../components/atoms/SEO"
import NotFoundPageContent from "../components/molecules/404/NotFoundPageContent"
import { OffersClient } from "../clients/OffersClient"

const InventoryDetail: FC<PageProps> = ({ params, location, index }) => {
  const [vehicle, setVehicle] = useState(null)
  const [vehicleEligibility, setVehicleEligibility] = useState(null)
  const { vin } = params
  const [vehicleImages, setImages] = useState([])
  const [interiorImages, setInteriorImages] = useState([])
  const [panoramicImage, setPanoramicImage] = useState(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const languageObj = useContext(LanguageContext)
  const [selectedImageView, setSelectedImageView] = useState("exterior")
  const [disclaimerState, dispatch] = useContext(DisclaimersContext)
  const [{ dealers }] = useContext(LocationContext)
  const selectedFilters = location?.state?.params
  const [seriesOffers, setSeriesOffers] = useState([])

  useEffect(() => {
    const getData = async () => {
      try {
        const inventory = await InventoryClient.get({
          vin: vin,
          include: ["accessories", "standardEquipment", "characteristics"],
        })

        const vehicle: Vehicle = inventory.vehicles[0]
        setVehicle(vehicle)
        setLoading(false)

        // Add VIN to recently viewed vehicles if it isn't already added
        const recentlyViewedVINs: LocalStorageValue =
          LocalStorageClient.read("recentlyViewed")
        if (
          Array.isArray(recentlyViewedVINs) &&
          !recentlyViewedVINs.includes(vin)
        ) {
          LocalStorageClient.put("recentlyViewed", [vin])
        } else if (!recentlyViewedVINs) {
          LocalStorageClient.write("recentlyViewed", [vin])
        }
      } catch (error) {
        setLoading(false)
        setError(error)
        console.error(error)
      }
    }
    getData()
  }, [vin])

  useEffect(() => {
    if (vehicle) {
      setVehicleEligibility(vehicle?.vehicleEligibility)
    }

    const exteriorImages = []
    for (let i = 0; i < vehicle?.images.exterior.count; i++) {
      exteriorImages.push({
        image: `${vehicle.images.exterior.rootUrl}${i}.${vehicle.images.exterior.fileType}`,
        type: "exterior",
      })
    }
    setImages(exteriorImages)

    const interiorImages = []
    for (let i = 0; i < vehicle?.images.interior.count; i++) {
      interiorImages.push({
        image: `${vehicle.images.interior.rootUrl}${i}.${vehicle.images.interior.fileType}`,
        type: "interior",
      })
    }
    setInteriorImages(interiorImages)

    for (let i = 0; i < vehicle?.images.panoramic.count; i++) {
      setPanoramicImage(
        `${vehicle.images.panoramic.rootUrl}${i}.${vehicle.images.panoramic.fileType}`
      )
    }

    // Add Vehicle Disclaimers to disclaimers context array
    const vehicleDisclaimers = vehicle?.standardEquipmentDisclaimers?.map(
      (disclaimer: any) => {
        disclaimer.disclaimer = disclaimer.description
        return disclaimer
      }
    )
    if (vehicleDisclaimers?.length > 0) {
      const newDisclaimers = _.unionBy(
        vehicleDisclaimers,
        disclaimerState.disclaimers,
        "code"
      )
      dispatch(setDisclaimers(newDisclaimers))
    }

    const getOffersData = async () => {
      try {
        const offersData = await OffersClient.getManyOffers({
          series: JSON.stringify(sanitizeSeries(vehicle?.series, false)),
          seriesCount: true,
        })
        if (offersData.offers) {
          setSeriesOffers(offersData.offers)
        }
      } catch (error) {
        console.error("Error fetching offers data", error)
      }
    }
    vehicle?.series && getOffersData()
  }, [vehicle])

  const formatSeriesForInventory = (series: string) => {
    if (!series) return ""
    let inventoryLink = series
      .replaceAll(" ", "")
      .replaceAll("/", "")
      .replaceAll("-", "")
      .replace("4x2", "")
      .replace("4x4", "")
      .replace("4X2", "")
      .replace("4X4", "")

    // remove hybrid from slug
    if (series.includes("Hybrid")) {
      inventoryLink = series.replace("Hybrid", "").replaceAll(" ", "")
    }
    if (series == "86" || series == "GR86") {
      inventoryLink = "gr86"
    }
    return inventoryLink.toLowerCase()
  }

  let selectDealer = dealers?.find(
    (dealer: { DealerCode: string }) => dealer.DealerCode == vehicle?.dealer
  )

  //Tealium
  const { handleTealView } = useTealiumView()
  const { setVehicleTealData } = useTealiumContext()
  const { trackTealEvent } = useTealiumEvent()
  const { vehicleTealData } = useTealiumContext()
  const [hasPortPhotos, setHasPortPhotos] = useState(null)

  useEffect(() => {
    if (vehicleImages && vehicleImages.length > 0) {
      setHasPortPhotos(vehicleImages?.length > 4 ? "true" : "false")
    }
  }, [vehicleImages])

  // Model name includes 4x designation for trucks, whereas seriesName excludes it
  const modelName = vehicle?.series
  const seriesName = remove4by(vehicle?.series)

  const category = vehicleCategory(vehicle?.series)

  const dealerName =
    vehicle?.dealerName && firstLettersCapital(vehicle?.dealerName)

  const { inventoryCarouselTealClick, handleClick, addAnalyticIds } =
    useCustomCarouselEvent(vehicle, category, "gallery", index, hasPortPhotos)
  useEffect(() => {
    if (vehicle && hasPortPhotos) {
      // page_name uses lowercase series name, no spaces
      const serieslc = sanitizeSeries(vehicle?.series)
      // Model name includes 4x designation for trucks, whereas seriesName excludes it
      const model = vehicle?.series
      const series = remove4by(model)

      handleTealView({
        page_name: `${category}_${serieslc}`,
        tealium_event: "model_vin",
        page_type: "inventory vdp",
        vehicle_segment_vehicle_page: category,
        vehicle_model: model,
        vehicle_model_truncated: series,
        vehicle_exterior_color: vehicle?.color.exterior.name,
        vehicle_year: vehicle?.year,
        vehicle_interior_color: vehicle?.color.interior.name,
        vehicle_trim: vehicle?.grade,
        vehicle_trim_id: vehicle?.modelNumber,
        vehicle_vin: vehicle?.vin,
        allocated_dealer_name: dealerName,
        allocated_dealer_code: vehicle?.dealer,
        vehicle_sale_price: `$${toUsd(vehicle?.msrp)}`,
        has_port_photos: hasPortPhotos,
      })
      setVehicleTealData({
        vehicle_segment_vehicle_page: category,
        vehicle_model: model,
        vehicle_model_truncated: series,
        vehicle_exterior_color: vehicle?.color.exterior.name,
        vehicle_year: vehicle?.year,
        vehicle_interior_color: vehicle?.color.interior.name,
        vehicle_trim: vehicle?.grade,
        vehicle_trim_id: vehicle?.modelNumber,
        vehicle_vin: vehicle?.vin,
        vehicle_sale_price: `$${toUsd(vehicle?.msrp)}`,
        allocated_dealer_name: dealerName,
        allocated_dealer_code: vehicle?.dealer,
      })
    }
  }, [vehicle, hasPortPhotos])

  const vehicleDescription = `${vehicle?.year ?? ""} ${vehicle?.series ?? ""} ${
    vehicle?.grade ?? ""
  }`
  const vehiclePrice =
    vehicle?.msrp &&
    selectDealer?.DealerFee &&
    `$${toUsd(vehicle?.msrp + selectDealer?.DealerFee)}`
  const titleValue = `${vehicleDescription ?? ""} ${vehiclePrice ?? ""}`
  const truncatedTitle =
    titleValue.length > 60 ? titleValue.substring(0, 60) + "..." : titleValue

  const interestedInTranslation = languageObj?._(
    "I'm interested in test driving the"
  )
  const vinTranslation = languageObj?._("VIN")
  const leadDetails = {
    vehicle: vehicle,
    leadType: "New Inventory Availability",
    contactButtonType: "ContactDealer",
    confirmContactDealerSelection: true,
    comment: `${interestedInTranslation} ${seriesName} ${vehicle?.grade}, ${vinTranslation} #${vehicle?.vin}.`,
  }

  let backToResultsLink = ""

  if (selectedFilters) {
    backToResultsLink = `/${formatSeriesForInventory(
      vehicle?.series
    )}/inventory/?s=${selectedFilters}`
  } else {
    backToResultsLink = generateInventoryLink({
      series: formatSeriesForInventory(vehicle?.series),
    })
  }

  const schema = `
  {
    "@context": "http://schema.org",
    "@type": "Product",
    "name": "${vehicle?.series} - VIN: ${vehicle?.vin}",
    "description":
      "Explore details and specifications of this Toyota vehicle available in our inventory.",
    "url": "https://exploresetoyota.com/inventory/${vehicle?.vin}/",
    "image":
      "https://cdn.sanity.io/images/90qxor39/production/9bf3dd539c9eac39c1a4b2465e19d7191702222e-500x415.jpg",
    "brand": {
      "@type": "Brand",
      "name": "Toyota",
    },
    "offers": {
      "@type": "Offer",
      "priceCurrency": "USD",
      "price": "${vehiclePrice}",
      "availability": "http://schema.org/InStock",
    }
  }`

  return (
    <Layout>
      <SEO url={location.href} title={truncatedTitle} schema={schema} />
      {error && <NotFoundPageContent />}
      {loading && (
        <div
          css={[
            tw`grid min-h-[300px]`,
            tw`md:(grid-rows-2 grid-flow-row-dense w-full flex flex-row items-center justify-center min-h-[600px])`,
          ]}
        >
          <div>
            <span
              css={[
                tw`block mt-4 text-lg text-gray-700 font-bold text-center leading-tight`,
              ]}
            >
              {languageObj._("Hang tight, we're fetching the")} <br />{" "}
              {languageObj._("most current inventory for you")}
            </span>
            <div>
              <Lottie loop animationData={lottieJson} play />
            </div>
          </div>
        </div>
      )}
      {vehicle && (
        <>
          <div
            css={[
              tw`w-full h-full px-6 py-4 mt-2 flex content-center`,
              tw`lg:(px-8 py-4 mt-5)`,
            ]}
          >
            <div css={[tw`text-toyotaRed cursor-pointer `]}>
              <Back
                cta={languageObj._("Back to Results")}
                link={`${
                  languageObj.language === "es" ? "/es" : ""
                }${backToResultsLink}`}
                onClick={() => {
                  trackTealEvent({
                    tealium_event: "back_to_results",
                    vehicle_model: modelName,
                    vehicle_model_truncated: seriesName,
                    vehicle_exterior_color: vehicle.color.exterior.name,
                    vehicle_interior_color: vehicle.color.interior.name,
                    vehicle_trim: vehicle.grade,
                    vehicle_trim_id: vehicle.modelNumber,
                    vehicle_vin: vehicle.vin,
                    allocated_dealer_name: dealerName,
                    allocated_dealer_code: vehicle.dealer,
                    vehicle_sale_price: `$${toUsd(vehicle.msrp)}`,
                    vehicle_year: vehicle.year,
                    vehicle_segment_vehicle_page:
                      vehicleTealData.vehicle_segment_vehicle_page,
                  })
                }}
                analyticsId="back to results:header:"
              />
            </div>
          </div>
          <div
            css={[
              tw`grid`,
              tw`lg:(grid-rows-2 grid-flow-row-dense w-full flex flex-row items-start)`,
            ]}
          >
            <div css={[tw`hidden`, tw`lg:(block)`]}>
              <VehicleInfo vehicle={vehicle} offers={seriesOffers} />
              <Highlights vehicle={vehicle} />
            </div>
            <div>
              <section id="panorama" aria-label="Vehicle Gallery Section">
                {selectedImageView === "exterior" && (
                  <VehicleGallery
                    vehicleImages={vehicleImages}
                    vehicle={vehicle}
                    analyticsId={"exterior:gallery:gallery"}
                    vehicleEligibility={vehicleEligibility}
                    imageType="exterior"
                    location="inventory"
                    vehiclePosition={index}
                  />
                )}
                {selectedImageView === "interior" && (
                  <VehicleGallery
                    vehicleImages={interiorImages}
                    vehicle={vehicle}
                    analyticsId={"interior:gallery:gallery"}
                    imageType="interior"
                    location="inventory"
                    vehiclePosition={index}
                  />
                )}
                {selectedImageView === "panorama" && (
                  <div css={[tw`flex justify-center p-12`]}>
                    <ReactPannellum
                      id="1"
                      sceneId="firstScene"
                      imageSource={panoramicImage}
                      config={{
                        autoRotate: -2,
                        autoLoad: true,
                      }}
                      style={{ width: "100%", height: "600px" }}
                      onPanoramaLoaded={() => {
                        const el = ReactPannellum.getContainer()
                        el && el.addEventListener("mousedown", handleClick)
                        addAnalyticIds(el)
                      }}
                    />
                  </div>
                )}
              </section>
              {vehicleEligibility && (
                <div
                  css={[tw`text-center pt-5 mb-1 min-h-[44px]`, tw`lg:(pt-0)`]}
                >
                  <Pill
                    selected={selectedImageView === "exterior"}
                    alignment="center"
                    onClick={() => {
                      setSelectedImageView("exterior")
                      inventoryCarouselTealClick("Exterior")
                    }}
                    analytics-id="exterior:gallery:gallery"
                  >
                    {languageObj._("Exterior")}
                  </Pill>
                  <Pill
                    selected={
                      selectedImageView === "interior" ||
                      selectedImageView === "panorama"
                    }
                    alignment="center"
                    onClick={() => {
                      setSelectedImageView(
                        panoramicImage?.length > 0 ? "panorama" : "interior"
                      ),
                        inventoryCarouselTealClick("Interior")
                    }}
                    analytics-id="interior:gallery:gallery"
                  >
                    {languageObj._("Interior")}
                  </Pill>
                </div>
              )}
              <div css={[tw`block`, tw`lg:(hidden)`]}>
                <VehicleInfo vehicle={vehicle} offers={seriesOffers} />
                <Highlights vehicle={vehicle} />
              </div>
              <Features vehicle={vehicle} />
              <Specs vehicle={vehicle} />
            </div>
          </div>

          <SimilarVehicles vehicle={vehicle} />
        </>
      )}

      {seriesOffers && seriesOffers?.length > 0 && (
        <VehicleDetailOtherOffers offers={seriesOffers} />
      )}
    </Layout>
  )
}

export default InventoryDetail
